<template lang="html">
  <div class="cart-account-inputs">
    <div class="row">
      <div class="col-6 col-first-name">
        <div class="form-item mr-input" :class="formItemClass($v.fields.first_name)">
          <input
            v-model.lazy="$v.fields.first_name.$model"
            type="text"
            name="first-name"
            id="first-name"
            class="form-control"
            placeholder="Enter first name"
            @input="$v.fields.first_name.$reset()"
            @blur="$v.fields.first_name.$touch()"
          />
        </div>
      </div>
      <div class="col-6 col-last-name">
        <div class="form-item" :class="formItemClass($v.fields.last_name)">
          <input
            v-model.lazy="$v.fields.last_name.$model"
            type="text"
            name="last-name"
            id="last-name"
            class="form-control"
            placeholder="Enter last name"
            @input="$v.fields.last_name.$reset()"
            @blur="$v.fields.last_name.$touch()"
          />
        </div>
      </div>
    </div>

    <div class="form-item mt-15" :class="formItemClass($v.fields.email)">
      <input
        v-model.lazy="$v.fields.email.$model"
        type="text"
        name="email"
        id="email"
        class="form-control"
        placeholder="Enter email address"
        @input="$v.fields.email.$reset()"
        @blur="$v.fields.email.$touch()"
      />
    </div>

    <div class="mt-30">
      <p class="login-link">
        <a data-toggle="modal" data-target="#modal-login">Existing customer? Click here to continue.</a>
      </p>
    </div>
  </div>
</template>

<script>

import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  props: {
    fields: { type: Object, required: true },
  },
  validations: {
    fields: {
      first_name: { required },
      last_name: { required },
      email: { required, email }
    }
  },
  methods: {
    formItemClass(field) {
      return {
        'mr-input': true,
        'error': field.$error
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.login-link {
  text-align: center;
  a {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
